import * as React from 'react';
import { Route } from 'react-router';
import loadable from '@loadable/component';

import { PageLoaderFallback } from '../layout/PageLoaderFallback';
import { ValuationContextProvider } from '../../utils/context/ValuationContext';

const ValuationBroker = loadable(
  () => import(/* webpackPrefetch: true */ './valuation/Broker'),
  {
    fallback: <PageLoaderFallback />
  }
);
const ValuationOffice = loadable(
  () => import(/* webpackPrefetch: true */ './valuation/Office'),
  {
    fallback: <PageLoaderFallback />
  }
);

const DefaultGeneral = loadable(
  () => import(/* webpackPrefetch: true */ './valuation/DefaultGeneral'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Winner = loadable(
  () => import(/* webpackPrefetch: true */ './valuation/DefaultGeneral2'),
  {
    fallback: <PageLoaderFallback />
  }
);

const ValuationGeneral1 = loadable(
  () => import(/* webpackPrefetch: true */ './valuation/General'),
  {
    fallback: <PageLoaderFallback />
  }
);
const ValuationGeneral2 = loadable(
  () => import(/* webpackPrefetch: true */ './valuation/General2'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Valuation: React.FC = () => {
  return (
    <ValuationContextProvider>
      <Route
        exact
        path="/verdivurdering-selge-eiendom"
        component={DefaultGeneral}
      />
      <Route
        exact
        path="/verdivurdering-selge-eiendom-winner"
        component={Winner}
      />
      <Route
        path="/verdivurdering-selge-eiendom/1"
        component={ValuationGeneral1}
      />
      <Route
        path="/verdivurdering-selge-eiendom/2"
        component={ValuationGeneral2}
      />
      <Route
        exact
        path="/megler/:broker/verdivurdering-selge-eiendom"
        component={ValuationBroker}
      />
      <Route
        exact
        path="/:urltag/verdivurdering-selge-eiendom"
        component={ValuationOffice}
      />
    </ValuationContextProvider>
  );
};

export default Valuation;
